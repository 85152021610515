



































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Contract} from '@/model/resource/Contract'
import {InputContractSchema} from '@/schema/resource/Contract/InputContractSchema'
import {$} from '@/facade'

@Component({})
export default class ContractModal extends Vue {
  @Prop({type: Contract, required: true}) contract!: Contract

  showModal: boolean = false
  schema = new InputContractSchema()
  open = false

  created() {
    this.schema.collectionDapp.idBlockchainVersionFk =
      this.contract.blockchainVersion?.$id ?? null
  }

  private toggleModal(): void {
    this.showModal = !this.showModal
  }

  getClassProps() {
    return this.contract.contractNep17?.$id ? 'large' : 'small'
  }

  getProps() {
    return this.contract.contractNep17?.$id
      ? this.schema.contractEditModeFields
      : this.schema.editModeFields
  }

  async persistContract() {
    await this.contract.persistContract()
    this.$toast.success('system.success.persist')
    this.showModal = false
    this.$emit('persisted')
  }
}
