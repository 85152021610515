














import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {InputSelect} from '@simpli/vue-input'
import {IResource} from '@simpli/resource-collection'
import {$} from '@/facade'

interface NullableBooleanOption extends IResource {
  value: boolean | null
}

@Component({
  components: {InputSelect},
})
export default class SelectNullableBoolean extends Vue {
  @Prop({type: Boolean, default: null}) value!: boolean | null
  @Prop({type: String, default: null}) label!: string | null
  @Prop({type: String, default: null}) innerClass!: string | null
  @Prop({type: String, default: () => $.t('boolean.null') as string})
  nullLabel!: string

  @Prop({type: String, default: () => $.t('boolean.true') as string})
  trueLabel!: string

  @Prop({type: String, default: () => $.t('boolean.false') as string})
  falseLabel!: string

  items = [
    {
      $id: 1,
      $tag: this.nullLabel,
      value: null,
    },
    {
      $id: 2,
      $tag: this.trueLabel,
      value: true,
    },
    {
      $id: 3,
      $tag: this.falseLabel,
      value: false,
    },
  ]

  get computedModel() {
    return this.items.find(i => i.value === this.value) ?? this.items[0]
  }

  set computedModel(val: NullableBooleanOption) {
    this.$emit('input', val.value)
  }

  get vBind() {
    return {...this.$attrs}
  }

  get vOn() {
    const listeners = {...this.$listeners}
    delete listeners.input
    return {...listeners}
  }
}
