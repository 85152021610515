










import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component
export default class RenderCopy extends Vue {
  @Prop({default: ''})
  value?: any

  @Prop({default: ''})
  content?: any

  @Prop({type: String})
  html?: string

  @Prop({type: String})
  innerClass?: string

  copy() {
    this.$utils.copyToClipboard(this.value)
    this.$toast.info('system.info.copiedToClipboard')
  }
}
