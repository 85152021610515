






















































































































import {Component, Vue} from 'vue-property-decorator'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterContract from '@/components/filters/FilterContract.vue'
import {Contract} from '@/model/resource/Contract'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {ListContractSchema} from '@/schema/resource/Contract/ListContractSchema'
import {ExportContractSchema} from '@/schema/resource/Contract/ExportContractSchema'
import ContractModal from '@/components/ContractModal.vue'
import ContractMailingListPopover from '@/components/ContractMailingListPopover.vue'

@Component({
  components: {
    ContractMailingListPopover,
    FilterToggle,
    FilterContract,
    ContractModal,
  },
})
export default class ListContractView extends Vue {
  schema = new ListContractSchema()
  collection = new ContractCollection()
  filterOpen = false

  async created() {
    this.collection.withDapp = false
    this.collection.setOrderBy('idContractPk')
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  async fetchContracts() {
    await this.$await.run('fetchLoading', async () => {
      await Contract.fetchContracts()
      await this.$utils.sleep(10000)
    })
    await this.refresh()
  }

  async toggleActiveItem(item: Contract) {
    if (item.active) {
      await this.$dialog.softDelete(item)
    } else {
      await this.$dialog.recoverContract(item)
    }
    if (item.active != null) {
      await item.softDeleteOrRecoverContract(item.idContractPk, !item.active)
    }
    await this.collection.queryAsPage()
  }

  async refresh() {
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new ContractCollection().clearFilters().addFilter(params)

    await coll.listExportContract()
    this.$xlsx.downloadFromSchema(coll.items, new ExportContractSchema())
  }
}
