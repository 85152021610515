


























import {Component, Prop, Provide, Vue} from 'vue-property-decorator'
import {InputGlobalSchema} from '@/schema/resource/Global/InputGlobalSchema'

@Component({})
export default class ContractMailingListPopover extends Vue {
  schema = new InputGlobalSchema()
  open = false
  global = this.$utils.clone(this.$auth.global)

  openPopover() {
    this.open = true
  }

  closePopover() {
    this.open = false
  }

  resetValues() {
    this.global = this.$utils.clone(this.$auth.global)
  }

  async persistMailingList() {
    await this.global.persistGlobal()
    this.$toast.success('system.success.persist')
    this.closePopover()
    await this.$auth.loadGlobal()
  }
}
